body {
  margin: 0;
  font-family: 'LatoRegular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'LatoRegular', sans-serif;
}

@font-face {
  font-family: "LatoRegular";
  src: local("LatoRegular"),
  url("./fonts/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
}